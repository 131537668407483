import { gql } from "@apollo/client";

export const User = {
  fragments: {
    UserBasicData: gql`
      fragment UserBasicData on User {
        _id
        role
        name
        email
        phone
        about
        chatToken
        chatKeys
        chatUUID
        deleted
      }
    `,
    UserAuthData: gql`
      fragment UserAuthData on User {
        _id
        role
        name
        email
        phone
        about
        chatToken
        chatKeys
        chatUUID
        foldersAccessToken
        notificationChannelGroupName
        organizationNotificationChannel
        notificationChannel
      }
    `,
    UserChatData: gql`
      fragment UserChatData on User {
        _id
        name
        email
        organizationName
        organizationType
      }
    `,
  },
};

export const WorkOrderType = {
  fragments: {
    WorkOrderTypeData: gql`
      fragment WorkOrderTypeData on TicketType {
        _id
        name
        color
        icon
        isInternal
        isSystem
        partsConfig {
          show
          isRequired
          fieldName
        }
        descriptionConfig {
          show
          isRequired
          fieldName
        }
        customFields {
          _id
          isRequired
          description
          customAdditionalField {
            _id
            type
            label
            fieldType
            options {
              _id
              value
              color
            }
          }
        }
      }
    `,
  },
};

export const Oem = {
  fragments: {
    OemBasicData: gql`
      fragment OemBasicData on Oem {
        _id
        logo
        thumbnail
        backgroundColor
        brandLogo
        heading
        subHeading
        paragraph
        textColor
        name
        urlOemFacility
        paidFeatures
      }
    `,
  },
};

const MachineBasicData = gql`
  fragment MachineBasicData on Machine {
    _id
    name
    serialNumber
    image
    thumbnail
    description
    oem {
      thumbnail
      logo
    }
    documentFolders {
      externalId
    }
    template {
      _id
      title
      type
      image
      thumbnail
      description
      documentFolders {
        externalId
      }
    }
  }
`;

export const Customer = {
  fragments: {
    CustomerBasicData: gql`
      fragment CustomerBasicData on Customer {
        _id
        name
      }
    `,
    CustomerFullData: gql`
      fragment CustomerFullData on Customer {
        _id
        name
        facilityId
        machines {
          ...MachineBasicData
        }
      }
      ${MachineBasicData}
    `,
  },
};

export const CustomizeField = {
  fragments: {
    AdditionFieldData: gql`
      fragment AdditionFieldData on AdditionalField {
        _id
        type
        slug
        label
        fieldType
        isAdditionalField
        enabled
      }
    `,

    CustomAdditionFieldData: gql`
      fragment CustomAdditionFieldData on CustomAdditionalField {
        _id
        slug
        label
        isAdditionalField
        enabled
      }
    `,
  },
};

export const Machine = {
  fragments: {
    MachineData: gql`
      fragment MachineData on Machine {
        _id
        customer {
          ...CustomerBasicData
        }
        name
        serialNumber
        image
        thumbnail
        description
        oem {
          logo
        }
        documentFolders {
          externalId
        }
        template {
          _id
          title
          type
          image
          thumbnail
          description
          documentFolders {
            externalId
          }
        }
      }
      ${Customer.fragments.CustomerBasicData}
    `,
    MachineListData: gql`
      fragment MachineListData on Machine {
        _id
        name
        description
        serialNumber
        thumbnail
        image
        oem {
          thumbnail
          logo
        }
        template {
          _id
          title
          type
          image
          thumbnail
          description
        }
        inventoryParts {
          part {
            _id
            name
            articleNumber
            description
            image
            thumbnail
          }
        }
      }
    `,
    MachineSingleData: gql`
      fragment MachineSingleData on Machine {
        _id
        name
        description
        thumbnail
        image
        serialNumber
      }
    `,
    MachinePartsData: gql`
      fragment MachinePartsData on Machine {
        _id
        inventoryParts {
          part {
            _id
            name
            articleNumber
            description
            image
            thumbnail
          }
        }
      }
    `,
    MachineFullData: gql`
      fragment MachineFullData on Machine {
        _id
        createdAt
        oem {
          ...OemBasicData
        }
        customer {
          ...CustomerBasicData
        }
        productionLine {
          _id
          name
        }
        name
        serialNumber
        image
        thumbnail
        description
        customFields {
          _id
          fieldId {
            ...CustomAdditionFieldData
          }
          value
        }
        template {
          _id
          title
          type
          image
          thumbnail
          description
          documentFolders {
            externalId
          }
        }
        documentFolders {
          externalId
        }
        detachedFromTemplate {
          documentation
        }
        _3dModelUrl
      }
      ${Oem.fragments.OemBasicData}
      ${Customer.fragments.CustomerBasicData}
      ${CustomizeField.fragments.CustomAdditionFieldData}
    `,
  },
};

const Schedule = {
  fragments: {
    TicketScheduleData: gql`
      fragment TicketScheduleData on Ticket {
        schedule {
          startTime
          endTime
          startTimezone
          endTimezone
          isAllDay
          isTimezoneEnabled
        }
      }
    `,
  },
};

export const Ticket = {
  fragments: {
    TicketData: gql`
      fragment TicketData on Ticket {
        _id
        ticketId
        title
        description
        status
      }
    `,
    TicketListData: gql`
      fragment TicketListData on Ticket {
        _id
        ticketId
        ticketType {
          ...WorkOrderTypeData
        }
        title
        description
        status
        createdAt
        facility {
          ...CustomerBasicData
        }
        isMyTicket
        machine {
          ...MachineBasicData
        }
        user {
          ...UserBasicData
        }
        ticketChatChannels
        unread
        assignees {
          ...UserBasicData
        }
        ticketInternalNotesChatChannels
        procedures {
          procedure {
            _id
            state
          }
        }
      }
      ${User.fragments.UserBasicData}
      ${MachineBasicData}
      ${Customer.fragments.CustomerBasicData}
      ${WorkOrderType.fragments.WorkOrderTypeData}
    `,

    TicketSingleData: gql`
      fragment TicketSingleData on Ticket {
        _id
        ticketId
        ticketType {
          ...WorkOrderTypeData
        }
        title
        description
        status
        createdAt
        assignees {
          ...UserBasicData
        }
        user {
          ...UserBasicData
        }
        machine {
          ...MachineBasicData
        }
        ticketChatChannels
        inventoryParts {
          part {
            _id
            name
            articleNumber
            image
            thumbnail
            description
          }
          quantity
        }
        procedures {
          procedure {
            _id
            createdAt
            name
            pdfUrl
            state
          }
        }
        ...TicketScheduleData
      }
      ${MachineBasicData}
      ${User.fragments.UserBasicData}
      ${Schedule.fragments.TicketScheduleData}
      ${WorkOrderType.fragments.WorkOrderTypeData}
    `,
  },
};

export const ProductionLine = {
  fragments: {
    ProductionLineData: gql`
      fragment ProductionLineData on ProductionLine {
        _id
        oem {
          ...OemBasicData
        }
        customer {
          ...CustomerBasicData
        }
        customFields {
          _id
          fieldId {
            ...CustomAdditionFieldData
          }
          value
        }
        name
        lineId
        description
        createdAt
        documentFolders {
          externalId
        }
      }
      ${Oem.fragments.OemBasicData}
      ${Customer.fragments.CustomerBasicData}
      ${CustomizeField.fragments.CustomAdditionFieldData}
    `,
    ProductionLineListData: gql`
      fragment ProductionLineListData on ProductionLine {
        _id
        oem {
          ...OemBasicData
        }
        customer {
          ...CustomerBasicData
        }
        numberOfMachines
        name
        lineId
        description
        createdAt
      }
      ${Oem.fragments.OemBasicData}
      ${Customer.fragments.CustomerBasicData}
    `,
  },
};

export const Component = {
  fragments: {
    ComponentData: gql`
      fragment ComponentData on Component {
        _id
        oem {
          ...OemBasicData
        }
        machine {
          ...MachineBasicData
        }
        name
        componentId
        description
        createdAt
        image
        thumbnail
      }
      ${MachineBasicData}
      ${Oem.fragments.OemBasicData}
    `,
    ComponentDetailData: gql`
      fragment ComponentDetailData on Component {
        _id
        oem {
          ...OemBasicData
        }
        machine {
          ...MachineBasicData
        }
        name
        componentId
        description
        createdAt
        image
        thumbnail
        documentFolders {
          externalId
        }
      }
      ${MachineBasicData}
      ${Oem.fragments.OemBasicData}
    `,
    ComponentPartsData: gql`
      fragment ComponentPartsData on Component {
        _id
        inventoryParts {
          part {
            _id
            name
            articleNumber
            description
            image
            thumbnail
          }
        }
      }
    `,
  },
};

export const InventoryPart = {
  fragments: {
    InventoryPartFullData: gql`
      fragment InventoryPartFullData on InventoryPart {
        _id
        name
        articleNumber
        description
        image
        thumbnail
        documentFolders {
          externalId
        }
      }
    `,
  },
};

export const MachineHistory = {
  fragments: {
    MachineHistoryData: gql`
      fragment MachineHistoryData on MachineHistory {
        year
        type
        resourceId
        resource
        note {
          message
          attachments {
            url
            type
            size
            name
            createdAt
          }
        }
        month
        machine
        _id
        createdAt
        createdBy {
          name
        }
        customer {
          _id
          name
        }
        ticket {
          _id
          title
          ticketId
          status
        }
      }
    `,
  },
};

export const Asset = {
  fragments: {
    AssetData: gql`
      fragment AssetData on Asset {
        _id
        customer {
          ...CustomerBasicData
        }
        name
        serialNumber
        image
        thumbnail
        description
        oem {
          logo
        }
        documentFolders {
          externalId
        }
        template {
          _id
          title
          image
          thumbnail
          description
          documentFolders {
            externalId
          }
        }
      }
      ${Customer.fragments.CustomerBasicData}
    `,
    AssetListData: gql`
      fragment AssetListData on Asset {
        _id
        name
        description
        serialNumber
        thumbnail
        image
        oem {
          thumbnail
          logo
        }
        template {
          _id
          title
          image
          thumbnail
          description
        }
        inventoryParts {
          part {
            _id
            name
            articleNumber
            description
            image
            thumbnail
          }
        }
        assetType
        hierarchy {
          id
          name
        }
        childrenCount
        totalChildrenCount
      }
    `,
    AssetSingleData: gql`
      fragment AssetSingleData on Asset {
        _id
        name
        description
        thumbnail
        image
        serialNumber
      }
    `,
    AssetPartsData: gql`
      fragment AssetPartsData on Asset {
        _id
        inventoryParts {
          part {
            _id
            name
            articleNumber
            description
            image
            thumbnail
          }
        }
      }
    `,
    AssetFullData: gql`
      fragment AssetFullData on Asset {
        _id
        createdAt
        oem {
          ...OemBasicData
        }
        customer {
          ...CustomerBasicData
        }
        name
        serialNumber
        image
        thumbnail
        description
        customFields {
          _id
          fieldId {
            ...CustomAdditionFieldData
          }
          value
        }
        template {
          _id
          title
          image
          thumbnail
          description
          documentFolders {
            externalId
          }
        }
        documentFolders {
          externalId
        }
        _3dModelUrl
        assetType
        hierarchy {
          id
          name
        }
        detachedFromTemplate {
          documentation
        }
        childrenCount
        totalChildrenCount
      }
      ${Oem.fragments.OemBasicData}
      ${Customer.fragments.CustomerBasicData}
      ${CustomizeField.fragments.CustomAdditionFieldData}
    `,
  },
};
