import {
  updateProductionLineCache,
  updateComponentCache,
  updateAssetCache,
} from "~/services";

export const documentFoldersListener = (m) => {
  if (!m?.message?.success) {
    return;
  }

  const payload = m?.message?.payload;
  const isMachine = m?.userMetadata?.isMachine;
  const isProductionLine = m?.userMetadata?.isProductionLine;
  const isComponent = m?.userMetadata?.isComponent;

  if (isMachine) {
    updateAssetCache(payload);
  }

  if (isProductionLine) {
    updateProductionLineCache(payload);
  }

  if (isComponent) {
    updateComponentCache(payload);
  }
};
